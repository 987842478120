body {
  margin: 0;
  font-family: 'Lato';
  background-color: #f0f0f0;
}

/* Header */

.app{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  background-color: #333;
  color: #fff;
  display: flex;
  top: 0;
  position: sticky;
  justify-content: space-around;
  align-items: center;
  height: 80px;
}

.header-left {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.header-right {
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: space-evenly;
  height: 60px;
}

.header-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.state-language {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'Lato';
}

.dropdown-arrow {
  margin-left: 5px;
}

.headerImage{
  width: 40;
  height: 40;
}

.header-button{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.header-item {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  flex-direction: column;
  margin-top: 5px;
  font-family: 'Lato';
  text-decoration: none;
}

.header-item img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.header-item:hover {
  color: #ddd;
}

.home h2{
      justify-content: center;
    align-items: center;
    display: flex;
}

@media (max-width: 480px) {
  .header {
    height: 60px;
    justify-content: space-around;
  }
  
  .header-left, .header-right {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .header-right {
    width: 300%;
    justify-content: space-between;
    height: 50px;
  }
  
  .header-logo {
    width: 30px;
    height: 30px;
  }
  
  .header-item img {
    width: 20px;
    height: 20px;
  }
  
  .header-item {
    font-size: 10px;
  }
}

@media (min-width: 480px) {
  .header {
    height: 60px;
    justify-content: space-around;
  }
  
  .header-left, .header-right {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .header-right {
    width: 25%;
    justify-content: space-between;
    height: 50px;
  }
  
  .header-logo {
    width: 30px;
    height: 30px;
  }
  
  .header-item img {
    width: 20px;
    height: 20px;
  }
  
  .header-item {
    font-size: 10px;
  }
}

/* Tablets (up to 768px) */
@media (min-width: 768px) {
  .header {
    height: 70px;
    justify-content: space-between;
  }
  
  .header-left, .header-right {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .header-right {
    width: 25%;
    justify-content: space-around;
    height: 55px;
  }
  
  .header-logo {
    width: 35px;
    height: 35px;
  }
  
  .header-item img {
    width: 22px;
    height: 22px;
  }
  
  .header-item {
    font-size: 15px;
  }
}

/* Laptops/Desktops (up to 1024px) */
@media (min-width: 1024px) {
  .header {
    height: 80px;
    justify-content: space-around;
  }
  
  .header-left, .header-right {
    padding-left: 25px;
    padding-right: 25px;
  }
  
  .header-right {
    width: 15%;
    justify-content: space-between;
    height: 60px;
  }
  
  .header-logo {
    width: 40px;
    height: 40px;
  }
  
  .header-item img {
    width: 24px;
    height: 24px;
  }
  
  .header-item {
    font-size: 16px;
  }
}

/* Large Devices (up to 1440px) */
@media (min-width: 1440px) {
  .header {
    height: 90px;
  }

  .header-left, .header-right {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .header-right {
    width: 20%;
    justify-content: space-evenly;
    height: 70px;
  }
  
  .header-logo {
    width: 50px;
    height: 50px;
  }
  
  .header-item img {
    width: 28px;
    height: 28px;
  }
  
  .header-item {
    font-size: 18px;
  }
}
