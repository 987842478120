/* Base Styles */
.rto-privacy-policy {
  background-color: #f9f9f9;
      width: 60%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      height: auto;
      align-self: center;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  
  .rto-privacy-policy h1,
  .rto-privacy-policy h2 {
    color: black;
    font-weight: bold;
  }
  
  .rto-privacy-policy ul {
    margin-left: 20px;
  }
  
  .rto-privacy-policy p {
    margin-bottom: 16px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .rto-privacy-policy {
      padding: 10px;
      font-size: 14px;
    }
  
    .rto-privacy-policy h1 {
      font-size: 20px;
      text-align: center;
    }
  
    .rto-privacy-policy h2 {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .rto-privacy-policy {
      padding: 8px;
      font-size: 12px;
    }
  
    .rto-privacy-policy h1 {
      font-size: 18px;
      text-align: center;
    }
  
    .rto-privacy-policy h2 {
      font-size: 16px;
    }
  
    .rto-privacy-policy ul {
      margin-left: 15px;
    }
  }
  