.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 15px 10px;
  position: sticky;
  bottom: 0;
  /* width: 100%; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.footer p {
  font-size: 1rem;
  margin: 0 10px; /* Adds some spacing between text and other elements */
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.footer-logo {
  max-width: 80px;
  height: auto;
  margin: 0 10px;
}

.right-container-box {
  display: flex;
  flex-wrap: wrap; /* Makes the layout adapt to smaller screens */
  justify-content: center;
  gap: 20px; /* Adds space between items */
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    padding: 20px;
  }

  .footer p {
    font-size: 0.9rem;
    margin: 10px 0;
  }

  .right-container-box {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .footer-logo {
    max-width: 60px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 10px;
  }

  .footer p {
    font-size: 0.8rem;
    margin: 5px 0;
  }

  .footer-logo {
    max-width: 50px;
  }
}
