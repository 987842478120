.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.addContainer {
  width: 20%;
  height: 100%;
}

.quiz {
  background-color: #f9f9f9;
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: auto;
}

.quizBox{
  border-bottom: 1px solid #C0C0C0;
  width: 100%;
  padding: 1%;
}

.quizBox p {
  text-align: left;
}

.quizBox .correct-answer p{
  font-family: 'Lato';
}

.correct-answer p{
  font-size: 1.25rem;
  font-weight: bold;
  color: green;
  width: 100%;
  text-align: left;
  font-family: 'Lato';
}

.question {
  margin-bottom: 20px;
  font-size: 1.5rem;
  text-align: center;
  border-bottom: 2px solid black; 
  padding-bottom: 10px; 
  font-family: 'Lato';
}

.quiz button {
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 250px;
  font-family: 'Lato';
}

.quiz .options button {
  font-size: 1.2rem;
  padding: 15px 30px;
  font-family: 'Lato';
  border: 1px solid #efefef;
  width: 100%;
}

.quiz button.correct {
  background-color: #30fd68;
  color: white;
}

.quiz button.wrong {
  background-color: #f44336; 
  color: white;
}

.quiz button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.nextBtn{
  background-color: #333;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  align-self: center;
}

.nextBtn:hover {
  background-color: #333; 
  align-self: center;
}


.nextBtn:active {
  background-color: #333; 
  box-shadow: 0 5px #666; 
  transform: translateY(4px); 
  align-self: center;
}

.options {
  text-align: center;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.options button {
  padding: 10px 15px;
  /* margin: 50px; */
  cursor: pointer;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  width: 100%;
  max-width: 200px;
}

.quiz button {
  margin-top: 20px;
  text-align: center;
  width: fit-content;
  width: max-content;
}

.correct {
  background-color: #1cbc48;
  color: white;
  text-align: left;
}

.wrong {
  background-color: #e6635b;
  color: white;
  text-align: left;
}

@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }

  .addContainer {
  display: none;
  }

  .quiz {
    background-color: #f9f9f9;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: auto;
  }

  .quizBox{
    border-bottom: 1px solid #C0C0C0;
    width: 100%;
    padding: 1%;
  }



  .quizBox .correct-answer p{
    font-family: 'Lato';
  }

  .correct-answer p{
    font-size: 1.25rem;
    font-weight: bold;
    color: green;
    width: 100%;
    text-align: left;
    font-family: 'Lato';
  }

  .options {
    text-align: center;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  
  .options button {
    padding: 10px 15px;
    /* margin: 50px; */
    cursor: pointer;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    width: 100%;
    max-width: 200px;
  }
  
  .quiz button {
    margin-top: 20px;
    text-align: center;
    width: fit-content;
    width: max-content;
  }
}

@media (max-width: 768px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }

  .addContainer {
  display: none;
  }

  .quiz {
    background-color: #f9f9f9;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: auto;
  }

  .quizBox{
    border-bottom: 1px solid #C0C0C0;
    width: 100%;
    padding: 1%;
  }



  .quizBox .correct-answer p{
    font-family: 'Lato';
  }

  .correct-answer p{
    font-size: 1.25rem;
    font-weight: bold;
    color: green;
    width: 100%;
    text-align: left;
    font-family: 'Lato';
  }
}

@media (min-width: 1024px) {
  .mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.addContainer {
  width: 20%;
  height: 100%;
}

.quiz {
  background-color: #f9f9f9;
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: auto;
}

.quizBox{
  border-bottom: 1px solid #C0C0C0;
  width: 100%;
  padding: 1%;
}

.quizBox p {
  text-align: left;
}

.quizBox .correct-answer p{
  font-family: 'Lato';
}

.correct-answer p{
  font-size: 1.25rem;
  font-weight: bold;
  color: green;
  width: 100%;
  text-align: left;
  font-family: 'Lato';
}
}

@media (min-width: 1440px) {
  .mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.addContainer {
  width: 20%;
  height: 100%;
}

.quiz {
  background-color: #f9f9f9;
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: auto;
}

.quizBox{
  border-bottom: 1px solid #C0C0C0;
  width: 98%;
  padding: 1%;
}

.quizBox p {
  text-align: left;
}

.quizBox .correct-answer p{
  font-family: 'Lato';
}

.correct-answer p{
  font-size: 1.25rem;
  font-weight: bold;
  color: green;
  width: 100%;
  text-align: left;
  font-family: 'Lato';
}
}

